console.log("file: BlogEP.ts")
import { createApp } from 'vue'
import BlogVC from '../components/blogvc.vue'
//import Vue3TouchEvents from "vue3-touch-events";
import mitt from 'mitt';
const emitter = mitt();
const app = createApp(BlogVC)
//app.use(Vue3TouchEvents)
app.config.globalProperties.window = window
app.config.globalProperties.navigator = window.navigator
app.config.globalProperties.emitter = emitter
app.config.globalProperties.globalI18nDict = {}
app.config.globalProperties.globals = {}
app.mount('#VueRootBlogVC')
require('../../src/material/material.ts')
