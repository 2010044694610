import { MDCFloatingLabel } from '@material/floating-label';
import { MDCTextField } from '@material/textfield';
import { MDCDialog } from '@material/dialog';
import { MDCLinearProgress } from '@material/linear-progress';
import { MDCTabBar } from '@material/tab-bar';
import { MDCRipple } from '@material/ripple/index';
import { MDCList } from "@material/list";
import { MDCTopAppBar } from '@material/top-app-bar';
import { MDCDrawer } from '@material/drawer';
import { MDCDataTable } from '@material/data-table';
import { MDCMenu } from '@material/menu';
import { MDCSelect } from '@material/select';
import { MDCTooltip } from '@material/tooltip';
import { MDCChipSet } from '@material/chips';
import { mdcAutoInit, MDCAttachable } from '@material/auto-init';

declare global {
	interface Element {
		MDC?: any; // 
	}
}

function initializeMDComponents(root: Document | Element, skipDialogs: boolean) {

	root.querySelectorAll('.mdc-floating-label').forEach(e1 => {
		if (!skipDialogs || (skipDialogs && !e1.closest('.mdc-dialog'))) {
			MDCFloatingLabel.attachTo(e1);
		}
	});

	root.querySelectorAll('.mdc-text-field').forEach(e1 => {
		if (!skipDialogs || (skipDialogs && !e1.closest('.mdc-dialog'))) {
			const instance = MDCTextField.attachTo(e1);
			e1.MDC = instance;
		}
	});

	// https://m2.material.io/components/dialogs/web#using-dialogs
	root.querySelectorAll('.mdc-dialog').forEach(e1 => {
		if (!e1.MDC) {
			const dialog = MDCDialog.attachTo(e1);
			e1.MDC = dialog;
			let notInitialized = true
			dialog.listen('MDCDialog:opened', () => {
				if (notInitialized) {
					notInitialized = false
					initializeMDComponents(e1, false)
				}
				// contentElement.setAttribute('aria-hidden', 'true');
			})
			dialog.listen('MDCDialog:closing', () => {
				//contentElement.removeAttribute('aria-hidden');
			});
		}
		//dialog.close();
	});

	root.querySelectorAll('.mdc-tab-bar').forEach(e1 => {
		if (!skipDialogs || (skipDialogs && !e1.closest('.mdc-dialog'))) {
			const tabBar = MDCTabBar.attachTo(e1);
			tabBar.listen('MDCTabBar:activated', function(event: CustomEvent) {
				const tabBarId = e1.id;
				const index = event.detail.index;
				// console.log("tab "+tabBarId+", no:"+index);
				[].forEach.call(root.querySelectorAll(`div[data-tabbarid="${tabBarId}"]`), function(tab: HTMLElement) {
					var tabno = tab.dataset.tabno;
					if (tabno == index) {
						tab.style.display = "block";
					} else {
						tab.style.display = "none";
					}
				})
			})
		}
	});

	root.querySelectorAll('.mdc-button').forEach(e1 => {
		if (!skipDialogs || (skipDialogs && !e1.closest('.mdc-dialog'))) {
			MDCRipple.attachTo(e1);
		}
	});

	root.querySelectorAll('.mdc-list').forEach(e1 => {
		if (!skipDialogs || (skipDialogs && !e1.closest('.mdc-dialog'))) {
			const list = MDCList.attachTo(e1);
			list.listElements.map(listItemEl => new MDCRipple(listItemEl));
		}
	});

	const topAppBarElement = root.querySelector('.mdc-top-app-bar');
	if (topAppBarElement) {
		const topAppBar = new MDCTopAppBar(topAppBarElement);
		root.querySelectorAll('.mdc-drawer').forEach(e1 => {
			if (!skipDialogs || (skipDialogs && !e1.closest('.mdc-dialog'))) {
				const drawer = MDCDrawer.attachTo(e1);
				topAppBar.listen('MDCTopAppBar:nav', () => {
					drawer.open = !drawer.open;
				});
			}
		});
	}

	root.querySelectorAll('.mdc-data-table').forEach(e1 => {
		if (!skipDialogs || (skipDialogs && !e1.closest('.mdc-dialog'))) {
			MDCDataTable.attachTo(e1);
		}
	});

	root.querySelectorAll('.mdc-menu').forEach(e1 => {
		if (!skipDialogs || (skipDialogs && !e1.closest('.mdc-dialog'))) {
			MDCMenu.attachTo(e1);
		}
	});

	root.querySelectorAll('.mdc-select').forEach(e1 => {
		if (!skipDialogs || (skipDialogs && !e1.closest('.mdc-dialog'))) {
			const select = MDCSelect.attachTo(e1);
			select.listen('MDCSelect:change', () => {
				alert(`Selected option at index ${select.selectedIndex} with value "${select.value}"`);
			});
		}
	});

	root.querySelectorAll('.mdc-tooltip').forEach(e1 => {
		if (!skipDialogs || (skipDialogs && !e1.closest('.mdc-dialog'))) {
			MDCTooltip.attachTo(e1);
		}
	});

	root.querySelectorAll('.mdc-chip-set').forEach(e1 => {
		if (!skipDialogs || (skipDialogs && !e1.closest('.mdc-dialog'))) {
			MDCChipSet.attachTo(e1);
		}
	});

}

// DOMContentLoaded sicherstellen, dass das DOM vollständig geladen ist
//document.addEventListener('DOMContentLoaded', () => {
initializeMDComponents(document, true)

// Auto-init
mdcAutoInit.register('MDCMenu', MDCMenu as MDCAttachable);
// https://github.com/material-components/material-components-web/tree/master/packages/mdc-linear-progress
// make sure to use same key as in MDCHandle.
// alternatively set instance manually  (check MDCTextField example above). 
mdcAutoInit.register('MDC', MDCLinearProgress as MDCAttachable);
mdcAutoInit();

//});
