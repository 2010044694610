<template>
	<header class="mdc-top-app-bar" >
		<div class="mdc-top-app-bar__row app-bar">
			<section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
				<!-- menu icon -->
				<button class="material-icons mdc-top-app-bar__navigation-icon mdc-icon-button" v-show="this?.linksMenu?.length>0">menu</button>
				<!-- app title -->
				<a class="mdc-button mdc-top-app-bar__title mdc-top-app-bar__action-item" href="/">{{appTitle}}</a>
			</section>
			<!-- toolbar links -->
			<section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end" role="toolbar">
				<div v-for="(item ) in this.linksToolBar" v-show="!(m_isDemo('demo1'))"><a class="mdc-button mdc-top-app-bar__action-item" v-bind:href="item.href">{{item.label}}</a></div>
			</section>
		</div>
	</header>
	<div class="mdc-drawer-app-content mdc-top-app-bar--fixed-adjust">
		<aside class="mdc-drawer mdc-drawer--dismissible">
			<div class="mdc-drawer__content">
				<div class="mdc-list">
					<div v-for="(item ) in this.linksMenu"><a class="mdc-list-item" v-bind:href="item.href">{{item.label}}</a></div>
				</div>
			</div>
		</aside>
		<main v-bind:class="{ 'app-body' : !isfullwidth , 'app-body-fullwidth' : isfullwidth }"><a class="mdc-button" href="?" v-show="debugIsVisible==true"><i class="material-icons mdc-button__icon" aria-hidden="true">refresh</i></a>
		<a class="mdc-button" href="?vue=update" v-show="debugIsVisible==true"><i class="material-icons mdc-button__icon" aria-hidden="true">published_with_changes</i></a>
		<button class="mdc-button" v-on:click="m_toggleWidth" v-show="debugIsVisible==true"><div class="mdc-button__ripple"></div>
		
		<i class="material-icons mdc-button__icon" aria-hidden="true">aspect_ratio</i></button>
		<slot name="slot_main"></slot>
		<BlogEntryVC v-bind:blogEntry="blogVCEntry" v-bind:p_instanceId="'id_29'" v-bind:p_vueDomain="c_vueDomain+'.'+'BlogEntryVC'"></BlogEntryVC>
		<div class="mdc-form-field" v-for="(item , index) in Object.keys(ddeebbuugg)" v-show="debugIsVisible==true"><div class="mdc-checkbox"><input class="mdc-checkbox__native-control" id="id_30" type="checkbox" v-model="ddeebbuugg[item]">
		<div class="mdc-checkbox__background">
			<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
				<path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
			</svg>
			<div class="mdc-checkbox__mixedmark"></div>
		</div>
		<div class="mdc-checkbox__ripple"></div>
		</div>
		<label for="id_30">{{item}}</label></div></main>
		<div class="footer">
			<button class="mdc-button" v-on:click="debugIsVisible=!debugIsVisible"></button>
			{{copyRight}}
			<span v-for="(item ) in this.linksFooter"><a v-bind:href="item.href">{{item.label}}</a></span>
			<span class="footer-cmmit-id">
				{{commitId}}
			</span>
		</div>		
	</div>
</template>

<script>
	// <script lang="ts">
	import $ from 'jquery';
	// see de.vdoemming.blog.vue.components.blog.BlogVC
	import BlogEntryVC from './blogentryvc.vue'
	export default {
		props: {
			p_vueDomain: {
						type: String,
						required: false
					},
			p_instanceId: {
						type: String,
						required: false
					},
			commitId: {
						type: String,
						required: false,
						default: '2361b72195'
					}
		}
		,
		data() {
			return {"layout":{"linksToolBar":[],"linksFooter":[],"linksMenu":[]},"linksToolBar":[],"d_userLanguage":"de","linksFooter":[],"d_i18nLocalDB":{},"copyRight":"© Andreas von Dömming","debugIsVisible":false,"blogVCEntry":{},"ddeebbuugg":{"BlogVC":false,"BlogEntryVC":false},"isfullwidth":false,"linksMenu":[]}
		}
		,
		computed: {
			appTitle() {
				if(this.m_isDemo('demo1')){
					return 'KI-Demo'
				}
				return 'vdoemming.de'
			}
			,
			c_vueDomain() {
				if(this.p_vueDomain==undefined){
					return 'BlogVC'
				}else{
					return this.p_vueDomain
				}
			}
			,
			console() {
				return console
			}
		}
		,
		components: {
			BlogEntryVC
		}
		,
		methods: {
			i18n: function(key){
				// check local
				const langObject=this.d_i18nLocalDB[key]
				if(langObject){
					const text=langObject[this.d_userLanguage]
					if(text){
						return text
					}
				}				
				// check global
				var result=this.globalI18nDict[key];
				if(result===undefined){
					return key
				}else{
					return result
				}
			}
			,
			m_isDemo: function(...demoIds){
				const urlParams = new URLSearchParams(window.location.search)
				const value=urlParams.get('demo')
				for (let demoId of demoIds) {
					if(value==demoId){
						return true
					}
				}
				// default:
				return false
			}			
			,
			m_toggleWidth: function(){
				this.isfullwidth=!this.isfullwidth;
				this.emitter.emit('page-layout-resized-event',this.c_vueDomain)
			}
		}
		,
		mounted() {
			const thisVue=this
			
			console.log('mounted component on vue domain: '+this.c_vueDomain)
			require('axios').default.get('/data/layout',{'Content-Type': 'application/json;charset=utf-8'}).then(response => {
				// handle success
				var resultData = response.data;
				this.layout=resultData
				// console.log(response);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.then(function () {
				// always executed
			});		
			
			require('axios').default.get('/data/menu',{'Content-Type': 'application/json;charset=utf-8'}).then(response => {
				// handle success
				var resultData = response.data;
				this.linksMenu=resultData
				// console.log(response);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.then(function () {
				// always executed
			});		
			
			require('axios').default.get('/data/toolbar',{'Content-Type': 'application/json;charset=utf-8'}).then(response => {
				// handle success
				var resultData = response.data;
				this.linksToolBar=resultData
				// console.log(response);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.then(function () {
				// always executed
			});		
			
			require('axios').default.get('/data/footer',{'Content-Type': 'application/json;charset=utf-8'}).then(response => {
				// handle success
				var resultData = response.data;
				this.linksFooter=resultData
				// console.log(response);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.then(function () {
				// always executed
			});		
			
			require('axios').default.get('/',{'Content-Type': 'application/json;charset=utf-8'}).then(response => {
				// handle success
				var resultData = response.data;
				this.blogVCEntry=resultData
				// console.log(response);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.then(function () {
				// always executed
			});		
		}
		,
		provide() {
			return {}
		}
	}
</script>
